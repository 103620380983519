<template>
  <div class="news-list" v-loading="pageLoading">
    <crumbs-custom />
    <div class="cont">
      <div class="search-box">
        <el-input
          placeholder="请输入关键字"
          v-model="search_key"
          class="input-with-select"
        >
          <el-button slot="append" @click="doSearch()">搜索</el-button>
        </el-input>
      </div>
      <div class="cont-list" v-if="dataList.length > 0">
        <div
          class="border-b list-cell cursor-pointer p-20"
          v-for="(item, index) in dataList"
          :key="index"
          @click="
            $router.push({ name: 'newsShow', params: { id: item.ac_id } })
          "
        >
          <div class="list-title">{{ item.ac_title }}</div>
          <div class="list-bref m-t-5">{{ item.ac_resume }}</div>
          <div class="list-other flex-row m-t-5">
            <div class="flex-cell"></div>
            <div class="">
              <i class="iconfont iconriqi"></i>发布日期：{{ item.ac_pub_time }}
            </div>
            <div class="m-l-15">
              <i class="iconfont iconlianjiewangzhi"></i>来源：
              {{ item.ac_pub_source || "" }}
            </div>
          </div>
        </div>
      </div>
      <el-empty v-if="dataList.length <= 0" description="暂无数据"></el-empty>
      <pagination
        v-if="dataList.length > 0"
        :total="total"
        :curPage="pageParams.page"
        :perPage="pageParams.size"
        @current-change="currentChange"
      />
    </div>
  </div>
</template>

<script>
import { requestPost, requestGet } from "@/api/home/index";
export default {
  name: "Home",
  props: {},
  data() {
    return {
      pageLoading: false,
      pageParams: {
        page: 1,
        size: 10,
      },
      total: 0,
      dataList: [],
      search_key: this.$route.params.val,
    };
  },
  created() {
    console.log(this.$route)
    this.$store.commit("CRUMBS_CUSTOM", [{title: '站内文章', router: '/credit/'+this.$route.params.val}]);
    // 加载数据
    if (this.$route.params.val && this.$route.params.val != "")
      l_loadData(this);
  },
  beforeRouteUpdate(to, from, next) {
    this.search_key = to.params.val + "";
    this.$store.commit("CRUMBS_CUSTOM", [{title: '站内文章', router: '/credit/'+this.$route.params.val}]);
    next();
    if (this.search_key && this.search_key != "") l_loadData(this);
  },
  methods: {
    // @@ 改变当前页事件
    currentChange(val) {
      this.pageParams.page = val;
      l_loadData(this);
    },
    // @@ 搜索
    doSearch() {
      if (!this.search_key) return this.$message.error("请输入搜索条件");
      l_loadData(this);
    },
  },
};

// 获取数据
function l_loadData(pg) {
  pg.pageLoading = true;
  var values = pg.pageParams;
  requestGet("api/article/search/" + pg.search_key, values)
    .then((res) => {
      pg.dataList = res.data.pageData || [];
      pg.total = res.data.count;
      pg.pageLoading = false;
    })
    .catch((error) => {
      pg.$message.error(error);
      pg.pageLoading = false;
    });
}
</script>
<style lang="scss" scoped>
.news-list {
  width: 1200px;
  margin: 0 auto;
  .cont {
    background: #fff;
    padding: 20px;
    .search-box {
      border-bottom: 2px solid #f00;
      padding: 40px 240px;
      ::v-deep .el-input-group__prepend {
        width: 150px !important;
        background: #fff;
      }
      ::v-deep .el-button {
        background: #db3e3e !important;
        color: #fff;
        font-weight: bold;
        border-radius: 0;
        border: 1px solid #db3e3e !important;
        width: 100px;
      }
    }
    .cont-list {
      .list-cell {
        border-bottom: 1px solid #e8e8e8;
        .list-title {
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
        }
        .list-bref {
          font-size: 14px;
          line-height: 22px;
        }
        .list-other {
          color: #606060;
          font-size: 14px;
          .iconfont {
            margin-right: 10px;
            color: #db3e3e;
            font-size: 13px;
          }
        }
      }
      .list-cell:hover {
        background: #e6f7ff;
      }
    }
  }
}
</style>
